@import "@frontend/jetlend-web-ui/src/styles/tools";

.external-auth {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(2, max-content);

    grid-template-columns: auto;
    margin-bottom: 20px;
}

.description {
    grid-column: 1 / -1;
    color: #A2A8AD;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    margin-bottom: 12px;
}

.buttons-wrapper {
    width: 100%;
    display: flex;
    grid-column: 1 / -1;
    column-gap: 12px;
}

.button {
    height: 56px;
    width: 100%;
    flex: 1;

    margin: 0;
    padding: 16px;

    box-shadow: none;
    border: 1px solid #d5dadc;
    border-radius: 12px;
    cursor: pointer;
    background: #FFF;

    transition: border 0.2s ease-in;

    &:hover {
        border: 1px solid $color-primary;
    }
}

.image {
    width: 100%;
    height: 100%;
    max-height: 24px;
    object-fit: contain;
}