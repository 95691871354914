@import "@frontend/jetlend-web-ui/src/styles/tools";

.formBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    display: inline-flex;

    background-color: #fff;
    padding: 50px 70px;

    border-radius: $border-radius;
    background: #fff;
    box-shadow: 0px 10px 40px 0px rgba(17, 17, 17, 0.08);

    width: 100%;
    /* box-sizing: inherit; */
    max-width: 450px;
    min-width: 450px;

    @include media-tablet {
        min-width: inherit;
    }

    @include media-desktop-md {
        padding: 40px 50px;
        gap: 24px;
    }

    @include media-mobile {
        padding: 30px 15px;
        gap: 16px;
    }


    &__title {
        color: $color-title-active;
        text-align: center;

        font-size: $font-size-subtitle;
        font-style: normal;
        font-weight: 600;
        line-height: 1.2;

        margin: 0;

        @include media-desktop-md {
            font-size: 30px;
        }

        @include media-mobile {
            font-size: $font-size--large;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        width: 100%;
    }
}