@import "@frontend/jetlend-web-ui/src/styles/tools";


.formBlock {

    &__input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        width: 100%;
    }

    &__label {
        color: $color-title-active;
        font-size: $font-size--xlarge;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 28px */
        margin-bottom: 12px;

        @include media-mobile {
            font-size: $font-size--small;
        }
    }

    &__field {
        width: 100%;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .remind {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
        }

        a {
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;

            @include media-mobile {
                font-size: $font-size--xsmall;
            }
        }
    }

    &__button {
        width: 100%;
    }

    &__legal {
        color: $color-grey--dark;

        /* fonts/Medium 16 */
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        /* 22.4px */

        max-width: 346px;

        flex-shrink: 0;

        margin-top: 32px;

        a {
            color: $color-primary;
        }

        @include media-mobile {
            font-size: $font-size--xsmall;
        }
    }

    &__login {
        align-self: center;

        margin-top: 32px;

        a {
            color: $color-grey--dark;
            text-decoration: underline;

            @include media-mobile {
                font-size: $font-size--xsmall;
            }
        }
    }

    &__confirm {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: 0.3px;
        color: $color-body;
        margin-bottom: 20px;
    }

    &__external-auth {
        margin-bottom: 20px;
    }
}

.button-container {
    width: 100%;
    margin: 0;
    padding: 0;

}

.remember-me,
.remember-me * {
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
}

.phone-text {
    margin: 0 auto;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    letter-spacing: 0.3px;
    color: $color-body;
    margin-bottom: 20px;
}

.phone {
    color: $color-secondary;
}